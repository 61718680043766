<template>
    <app-wrapper>
        <template v-slot:child>
            <UpdateInventoryModal :closeModal="() => toggleUpdateInventoryModal(false)" />
            <!--Customer Transactions Detail -->
            <div class="w-90 center pt3 font-w1">
                <!--        &lt; Inventory-->
                <router-link :to="{ name: 'Products' }"> &lt; Inventory </router-link>
            </div>
            <div class="w-90 center">
                <div class="flex flex-wrap justify-between items-center pv3 box-border-bottom">
                    <div>
                        <div v-if="productDetails?.product?.type === 'PRODUCTION'" class="pb3 pb0-l f3 b">
                            Finished Good Details
                        </div>
                        <div v-else-if="productDetails?.product?.type === 'RAW_MATERIAL'" class="pb3 pb0-l f3 b">
                            Raw Material Details
                        </div>
                        <div v-else class="pb3 pb0-l f3 b">Product Details</div>
                    </div>
                    <div class="scrollmenu">
                        <button
                            v-if="role === 'admin' || role === 'owner' || !rolePermissions?.includes(40)"
                            ref="archiveBtnRef"
                            class="mr2 btn3"
                            @click.prevent="archive(productDetails?.product)"
                            :disabled="state.disableArchive || productDetails?.product?.deletedAt !== undefined"
                        >
                            Archive Product
                        </button>

                        <button
                            v-if="role === 'admin' || role === 'owner' || !rolePermissions?.includes(40)"
                            @click.prevent="toggleUpdateInventoryModal(true, productDetails?.product)"
                            title="Update Inventory"
                            class="btn3"
                            :disabled="state.disableArchive || productDetails?.product?.deletedAt !== undefined"
                        >
                            <font-awesome-icon icon="fa-solid fa-plus-minus" class="fa-2x" />
                        </button>

                        <router-link :to="{ name: 'ProductUpdate' }">
                            <button
                                v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(36)"
                                class="btn2 ml2"
                            >
                                Edit Product
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
            <!--Customer Transactions Detail -->

            <template v-if="productLoading"><Spinner /></template>
            <template v-else>
                <!-- customer details, full name, phone-->
                <div class="w-90 center flex flex-wrap pt4">
                    <div class="w-25-l w-100 box-border pv4 tc">
                        <div class="flex justify-center items-center">
                            <div
                                class="flex justify-center items-center font-w3 box-border1"
                                style="background: rgb(215, 222, 250);
                                    height: 160px;
                                    width: 160px;
                                    border-radius: 50%;
                                    font-size: 55px;
                                    color: rgb(19, 44, 140);
                                    margin-bottom: 20px;
                                    overflow: hidden;
                                "
                            >
                                <img
                                    :src="productDetails?.product?.imageUrl || require('@/assets/images/inventory-default.png')"
                                    class="pr1 h-100 w-100 cover"
                                    :alt="productDetails?.product?.name"
                                    style="object-position: center"
                                />

                                <!--     style="object-position: center; height: 90%; width: 90%"-->
                                <!--  {{ `${productDetails?.product?.name?.split(' ')?.[0]?.split('')?.[0]?.toUpperCase() || ''}` }}-->
                            </div>
                        </div>

                        <div class="pt3 b" style="color: #193ab8; font-size: 22px">{{ productDetails?.product?.name }}</div>
                        <!-- <img src="../../assets/images/table-shelf.png" alt="" /> -->
                        <!-- <div class="pt3 b" style="color: #132C8C;">Table Shelf</div> -->
                    </div>

                    <div class="w-25-l w-100 box-border pa4">
                        <div class="pv2">
                            <div class="pb2" style="color: #55596ebf">Product Name</div>
                            <div>{{ productDetails?.product?.name }}</div>
                        </div>
                        <div class="pv2">
                            <div class="pb2" style="color: #55596ebf">SKU</div>
                            <div>{{ productDetails?.product?.sku }}</div>
                        </div>
                        <div class="pv2">
                            <div class="pb2 ttc" style="color: #55596ebf">Product ID</div>
                            <div>{{ productDetails?.product?._id?.toUpperCase() }}</div>
                        </div>
                        <div class="pv2">
                            <div class="pb2" style="color: #55596ebf">Size</div>
                            <div>{{ productDetails?.product?.size }}</div>
                        </div>
                        <div v-if="productDetails?.product?.measurement" class="pv2">
                            <div class="pb2" style="color: #55596ebf">Measurement</div>
                            <div>{{ productDetails?.product?.measurement }}</div>
                        </div>
                        <div v-if="productDetails?.product?.expiryDate" class="pv2">
                            <div class="pb2" style="color: #55596ebf">Expiry Date</div>
                            <div>{{ formatDate(productDetails?.product?.expiryDate, 'ddd Do MMMM, YYYY') }}</div>
                        </div>
                    </div>

                    <div class="w-25-l w-100 box-border pa4">
                        <div class="pv2">
                            <div class="pb2" style="color: #55596ebf">Category</div>
                            <div>{{ productDetails?.product?.categoryName || '-' }}</div>
                        </div>
                        <div class="pv2">
                            <div class="pb2" style="color: #55596ebf">Brand</div>
                            <div>{{ productDetails?.product?.brandName || '-' }}</div>
                        </div>
                        <div class="pv2">
                            <div class="pb2" style="color: #55596ebf">Variants</div>
                            <div>{{ formatQuantity(productDetails?.product?.variant) || 1 }}</div>
                        </div>
                        <div class="pv2">
                            <div class="pb2" style="color: #55596ebf">Color</div>
                            <div>{{ productDetails?.product?.color || '-' }}</div>
                        </div>
                        <div class="pv2">
                            <div class="pb2" style="color: #55596ebf">Barcode</div>
                            <div>{{ productDetails?.product?.barcode || '-' }}</div>
                            <div id="barcodeContainer" v-if="productDetails?.product?.isBarcodeAutoGenerated" class="center">
                                <vue3-barcode 
                                    :value="productDetails?.product?.barcode" 
                                    :height="50"
                                    elementTag="img"
                                    class="center"
                                />
                            </div>
                            <div v-if="productDetails?.product?.isBarcodeAutoGenerated" class="flex items-center justify-between">
                                <a href="" @click.prevent="downloadBarcode" download="barcode.png" target="_blank" class="active">Download</a>
                                <a href="#" @click.prevent="printBarcode" class="active">Print</a>
                            </div>
                        </div>
                    </div>

                    <div class="w-25-l w-100 box-border pa4">
                        <div class="pv2">
                            <div class="pb2" style="color: #55596ebf">Suppliers</div>
                            <div v-if="productDetails?.suppliers?.length" class="flex" style="gap: 0.3rem">
                                <div v-for="supplier of productDetails?.suppliers" :key="supplier">
                                    <span class="box-border1 pa1 b font-w1">
                                        <router-link :to="{ name: 'Entity', params: { id: supplier?.entity } }">
                                            {{ supplier?.nameOrCompany }}
                                        </router-link>
                                    </span>
                                </div>
                            </div>

                            <span v-else class="mr2">No suppliers found</span>

                            <!--     <template v-if="state?.suppliers">-->
                            <!--     <template v-for="supplier of state?.suppliers" v-bind:key="supplier">-->
                            <!--           <span class="mr2">{{ supplier || '-' }}, </span>-->
                            <!--    </template>-->
                            <!--      </template>-->

                            <!--    <template v-else v-for="supplier of productDetails?.product?.suppliers" v-bind:key="supplier + 1">-->
                            <!--   <span class="mr2">{{ supplier.company || supplier.supplier || '-' }}, </span>-->
                            <!--   </template>-->
                        </div>
                        <div class="pv2">
                            <div class="pb2" style="color: #55596ebf">Inventory Cost (Avg.)</div>
                            <div>
                                {{ formatAmount(productDetails?.product?.buyingPrice, $store.state.Settings?.currency) }}
                                ( {{ formatAmount(productDetails?.product?.averageCostPrice, $store.state.Settings?.currency) }})
                            </div>
                        </div>
                        <div class="pv2">
                            <div class="pb2" style="color: #55596ebf">Retail Price (Avg.)</div>
                            <div>
                                {{ formatAmount(productDetails?.product?.sellingPrice, $store.state.Settings?.currency) }}
                                ( {{ formatAmount(productDetails?.product?.averageSalesPrice, $store.state.Settings?.currency) }})
                            </div>
                        </div>
                        <div v-if="productDetails?.product?.rawMaterials" class="pv2">
                            <div class="pb2" style="color: #55596ebf">Raw Materials</div>
                            <div v-for="(rawMaterial, index) in productDetails?.product?.rawMaterials" :key="index">
                                <span>- {{ rawMaterial.name }} ({{ rawMaterial.quantity }}) - {{ rawMaterial.measurement }}</span>
                            </div>
                        </div>
                        <!--          <div class="pv2">-->
                        <!--            <div class="pb2" style="color: #55596ebf">Recommended Price</div>-->
                        <!--            <div>{{ formatAmount(productDetails?.summary?.recommendedSellPrice || 0) || '-' }}</div>-->
                        <!--          </div>-->
                    </div>
                </div>
                <div class="w-90 center">
                    <div class="pa4 box-border">
                        <div class="pb2 b">Product Description</div>
                        <div class="lh-copy">
                            {{ productDetails?.product?.description }}
                        </div>
                    </div>
                </div>
                <!-- customer details, full name, phone-->

                <!-- stock on hand value, current stock on hand , total qty purchased, average inventory -->
                <div class="w-90 center mv4">
                    <div class="flex flex-wrap adj-text font-w1">
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/revenue.png" alt="" />
                                <div class="pl2 lh-title ttc">
                                    Current Stock On Hand
                                    <span>
                                        <font-awesome-icon
                                            icon="fa-solid fa-question-circle"
                                            class="dashboard-icon-modal"
                                            @mouseover="totalStockOnHand = true"
                                            @mouseleave="totalStockOnHand = false"
                                        />
                                    </span>
                                    <div class="outstanding-modal" v-if="totalStockOnHand">
                                        This shows the current quantity of items available in your inventory. It helps you keep
                                        track of your stock levels and ensures you never run out of essential products.
                                    </div>
                                </div>
                            </div>
                            <div class="f4-l f6 b">{{ formatQuantity(productDetails?.product?.currStockOnHandQty) }}</div>
                        </div>
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/revenue.png" alt="" />
                                <div class="pl2 lh-title ttc">
                                    Stock On Hand Purchase Value
                                    <span>
                                        <font-awesome-icon
                                            icon="fa-solid fa-question-circle"
                                            class="dashboard-icon-modal"
                                            @mouseover="handPurchaseValue = true"
                                            @mouseleave="handPurchaseValue = false"
                                        />
                                    </span>
                                    <div class="outstanding-modal" v-if="handPurchaseValue">
                                        This represents the total cost of all the items currently in stock. It helps you
                                        understand the investment tied up in your inventory. It is calculated as (Qty in stock *
                                        Average purchase price/buying price)
                                    </div>
                                </div>
                            </div>
                            <div class="f4-l f6 b">
                                {{
                                    formatAmount(
                                        productDetails?.product?.stockOnHandPurchaseValue,
                                        $store.state.Settings?.currency
                                    )
                                }}
                            </div>
                        </div>
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/revenue.png" alt="" />
                                <div class="pl2 lh-title ttc">
                                    Stock On Hand Sales Value
                                    <span>
                                        <font-awesome-icon
                                            icon="fa-solid fa-question-circle"
                                            class="dashboard-icon-modal"
                                            @mouseover="handSalesValue = true"
                                            @mouseleave="handSalesValue = false"
                                        />
                                    </span>
                                    <div class="outstanding-modal" v-if="handSalesValue">
                                        This provides an estimate of your inventory value were you to sell all off at the current
                                        average sales price. It is calculated as (Qty in stock * Average sales price/Selling
                                        price).
                                    </div>
                                </div>
                            </div>
                            <div class="f4-l f6 b">
                                {{
                                    formatAmount(productDetails?.product?.stockOnHandSalesValue, $store.state.Settings?.currency)
                                }}
                            </div>
                        </div>
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/revenue.png" alt="" />
                                <!-- <div class="pl2 lh-title ttc">Average Inventory Day</div> -->
                                <div class="pl2 lh-title ttc">
                                    Number of days inventory
                                    <span>
                                        <font-awesome-icon
                                            icon="fa-solid fa-question-circle"
                                            class="dashboard-icon-modal"
                                            @mouseover="noOfDaysInventory = true"
                                            @mouseleave="noOfDaysInventory = false"
                                        />
                                    </span>
                                    <div class="outstanding-modal" v-if="noOfDaysInventory">
                                        This is how long it takes a company, on average, to sell all the products they have in
                                        their store. It aids in inventory planning and prevents understocking/overstocking
                                    </div>
                                </div>
                            </div>
                            <div class="f4-l f6 b">Coming Soon...</div>
                        </div>
                    </div>
                </div>
                <!-- stock on hand value, current stock on hand , total qty purchased, average inventory -->

                <!-- total cost, total qty sold, average price day, total sales, average cost -->
                <div class="w-90 center pt4">
                    <div class="flex flex-wrap adj-text font-w1">
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/total-cost.png" alt="" />
                                <div class="pl2 lh-title">
                                    Total Qty. {{ productDetails?.product?.type === 'PRODUCTION' ? 'Produced' : 'Purchased' }}

                                    <span v-if="productDetails?.product?.type !== 'PRODUCTION'">
                                        <span>
                                            <font-awesome-icon
                                                icon="fa-solid fa-question-circle"
                                                class="dashboard-icon-modal"
                                                @mouseover="totalQtyPurchase = true"
                                                @mouseleave="totalQtyPurchase = false"
                                            />
                                        </span>
                                        <div class="outstanding-modal" v-if="totalQtyPurchase">
                                            This shows the overall quantity of items purchased over a specific period. It gives
                                            you insights into your buying patterns and helps in understanding demand fluctuations.
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="f4-l f6 b">{{ formatQuantity(productDetails?.product?.totalQtyPurchased) }}</div>
                        </div>
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/total-qty.svg" alt="" />
                                <div class="pl2 lh-title">
                                    Total Cost Of {{ productDetails?.product?.type === 'PRODUCTION' ? 'Production' : 'Purchase' }}
                                    <span>
                                        <font-awesome-icon
                                            icon="fa-solid fa-question-circle"
                                            class="dashboard-icon-modal"
                                            @mouseover="totalCostOfPurchase = true"
                                            @mouseleave="totalCostOfPurchase = false"
                                        />
                                    </span>
                                    <div class="outstanding-modal" v-if="totalCostOfPurchase">
                                        This represents the total cost incurred for purchasing all the items within a certain
                                        timeframe. It's vital for tracking expenses and calculating profit margins.
                                    </div>
                                </div>
                            </div>
                            <div class="f4-l f6 b">
                                {{ formatAmount(productDetails?.product?.totalCostPrice, $store.state.Settings?.currency) }}
                            </div>
                        </div>
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/total-cost.png" alt="" />
                                <div class="pl2 lh-title">
                                    Total Qty. Sold
                                    <span>
                                        <font-awesome-icon
                                            icon="fa-solid fa-question-circle"
                                            class="dashboard-icon-modal"
                                            @mouseover="totalQtySold = true"
                                            @mouseleave="totalQtySold = false"
                                        />
                                    </span>
                                    <div class="outstanding-modal" v-if="totalQtySold">
                                        This indicates the total quantity of items sold over a specific period. It helps you
                                        assess your sales performance and measure customer demand.
                                    </div>
                                </div>
                            </div>
                            <div class="f4-l f6 b">{{ formatQuantity(productDetails?.product?.totalQtySold) }}</div>
                        </div>
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/total-qty.svg" alt="" />
                                <div class="pl2 lh-title">
                                    Total Sales
                                    <span>
                                        <font-awesome-icon
                                            icon="fa-solid fa-question-circle"
                                            class="dashboard-icon-modal"
                                            @mouseover="totalSalesPrice = true"
                                            @mouseleave="totalSalesPrice = false"
                                        />
                                    </span>
                                    <div class="outstanding-modal" v-if="totalSalesPrice">
                                        This displays the total revenue generated from selling all the items within a specific
                                        timeframe. It's crucial for evaluating your business's financial performance.
                                    </div>
                                </div>
                            </div>
                            <div class="f4-l f6 b">
                                {{ formatAmount(productDetails?.product?.totalSalesPrice, $store.state.Settings?.currency) }}
                            </div>
                        </div>
                        <!--                        <div class="w-20-l w-50 pa3 box-border">-->
                        <!--                            <div class="flex items-center pb3">-->
                        <!--                                <img src="../../assets/images/total-sale.svg" alt="" />-->
                        <!--                                <div class="pl2">Average Cost</div>-->
                        <!--                            </div>-->
                        <!--                            <div class="f3 b">$441</div>-->
                        <!--                        </div>-->
                    </div>
                </div>
                <!-- total cost, total qty sold, average price day, total sales, average cost -->

                <!-- stock on hand value, current stock on hand , total qty purchased, average inventory -->

                <!-- sales trend count & volume -->

                <!--      TODO: Graphs-->
                <!-- <div class="flex flex-wrap justify-between w-90 center mt4">
                       <div class="w-60-l w-100 box-border mb0-l mb4">
                         <div class="">
                           <div class="flex justify-between items-center pa3 box-border-bottom">
                             <p class="b">Sales trend-count</p>
                             <p>
                               <select name="" id="" style="padding: 5px">
                                 <option value="">Next 30days</option>
                                 <option value="">Next 7days</option>
                               </select>
                             </p>
                           </div>
                         </div>

                         <div class="">
                           <div class="pa3">
                             <div class="flex justify-between items-center">
                               <p class="w-20">248,500</p>
                               <div class="flex items-center">
                                 <p class="pl2 flex items-center">12.6% <img src="../../assets/images/increase-bg.svg" alt="" /></p>
                                 <p class="pl1">Last month 230,000</p>
                               </div>
                             </div>
                             <img src="../../assets/images/sales-trend-graph.png" class="pt4" alt="" />
                           </div>
                         </div>
                       </div>
                       <div class="w-34-l w-100 box-border">
                         <div class="">
                           <div class="flex justify-between items-center pa3 box-border-bottom">
                             <p class="b">Sales trend-volume</p>
                             <p>
                               <select name="" id="" style="padding: 5px">
                                 <option value="">Next 30days</option>
                                 <option value="">Next 7days</option>
                               </select>
                             </p>
                           </div>
                         </div>

                         <div class="">
                           <div class="pa3">
                             <div class="flex justify-between">
                               <p class="w-20">248,500</p>
                               <div class="flex items-center">
                                 <p class="pl2 flex items-center">12.6% <img src="../../assets/images/increase-bg.svg" alt="" /></p>
                                 <p class="pl1">Last month 230,000</p>
                               </div>
                             </div>
                             <img src="../../assets/images/sale-volume-graph.png" class="pt4" alt="" />
                           </div>
                         </div>
                       </div>
                     </div> -->
                <!-- sales trend count & volume -->

                <div class="w-90 center mv5">
                    <div
                        class="flex flex-wrap justify-between items-center"
                        style="border-top: 1px solid #e3e8ee; border-bottom: 1px solid #e3e8ee"
                    >
                        <div name="stats" id="stats" class="product-details-filter">
                            <span class="mr1">
                                Start date <input v-model="state.startDate" type="date" name="" class="input-style"
                            /></span>
                            <span> End date <input v-model="state.endDate" type="date" name="" class="input-style"/></span>
                            <button @click="onHandleCustomRange" class="flex items-center pa1 filter">
                                <img :src="require('@/assets/images/Reload.svg')" alt="" />
                            </button>
                        </div>
                    </div>
                </div>

                <!-- desktop content display -->
                <div class="scrollmenu w-90 center">
                    <table class="w-100 tl" cellspacing="0">
                        <thead>
                            <tr>
                                <th class="pb3 pr3 box-border-bottom tl">Reference No</th>
                                <th class="pb3 pr3 box-border-bottom tl">Date</th>
                                <th class="pb3 pr3 box-border-bottom tl">Transaction Type</th>
                                <th class="pb3 box-border-bottom tl pr2">Entity</th>
                                <th class="pb3 pr3 box-border-bottom tl">Quantity</th>
                                <th class="pb3 pr3 box-border-bottom tl">Cost per item</th>
                                <th class="pb3 box-border-bottom tl">Total Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="transaction of productDetails?.transactions" v-bind:key="transaction._id" class="font-w1">
                                <td class="pv3 pr3 box-border-bottom tl b">
                                    <router-link :to="{ name: 'Transaction', params: { id: transaction?.reference } }">
                                        {{ transaction?.number || transaction?.reference }}
                                    </router-link>
                                </td>
                                <td class="pv3 pr3 box-border-bottom tl">
                                    {{ formatDate(transaction?.date || transaction?.createdAt) }}
                                </td>
                                <td class="pv3 pr3 box-border-bottom tl flex items-center ttc">
                                    <img src="../../assets/images/file.svg" class="pr1" alt="" />
                                    <span v-if="transaction.subtype === 'damaged_stock'">Damaged Stock</span>
                                    <span v-else-if="transaction.subtype === 'missing_stock'">Missing Stock</span>
                                    <span v-else-if="transaction.transactionPoint === 'transfer_in'">Transfer In</span>
                                    <span v-else-if="transaction.transactionPoint === 'transfer_out'">Transfer Out</span>
                                    <span v-else-if="transaction.subtype === 'promotion_stock'">Promotion Stock</span>
                                    <span
                                        v-else-if="
                                            transaction?.type === 'purchases' && transaction?.transactionPoint === 'produce'
                                        "
                                        >Produce</span>
                                    <span
                                        v-else-if="
                                            transaction?.type === 'inflows' && transaction?.subtype === 'stock_introduced'
                                        "
                                        >Stock update</span>
                                    <span v-else-if="transaction?.type === 'outflows'">{{ transaction.subtype.split('_').join(' ').toString() }}</span>
                                    <span v-else>{{ transaction.type }}</span>
                                </td>
                                <td class="pv3 pr3 box-border-bottom tl">{{ transaction?.entityName }}</td>
                                <td class="pv3 pr3 box-border-bottom tl">{{ formatQuantity(transaction?.quantity) }}</td>
                                <td class="pv3 pr3 box-border-bottom tl">
                                    {{ formatAmount(transaction?.unit_price, $store.state.Settings?.currency) }}
                                </td>
                                <td class="pv3 pr3 box-border-bottom tl">
                                    {{ formatAmount(transaction?.amount, $store.state.Settings?.currency) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </template>
    </app-wrapper>
</template>

<script>
import { onMounted, computed, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import Swal from 'sweetalert2'
import Vue3Barcode from 'vue3-barcode'

import AppWrapper from '@/layout/AppWrapper'
import { formatAmount, formatDate, formatDateFilter, formatQuantity } from '@/utils/lib'
import Spinner from '@/components/Spinner'
import UpdateInventoryModal from '@/views/Inventory/widgets/UpdateInventoryModal'

export default {
    name: 'Product',
    components: { AppWrapper, Spinner, UpdateInventoryModal, Vue3Barcode },

    setup() {
        const day = new Date().getDate()
        const month = new Date().getMonth()
        const year = new Date().getFullYear()
        const store = useStore()
        const route = useRoute()
        const archiveBtnRef = ref(null)
        const productDetails = computed(() => store?.state?.Inventory?.productDetails)
        const productLoading = computed(() => store?.state?.Inventory?.productLoading)
        const role = computed(() => store?.state?.Auth?.role)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)

        const state = reactive({
            suppliers: [],
            company: '',
            startDate: new Date(year, 0, 1),
            endDate: new Date(year, month, day),
            disableArchive: false,
        })

        watch(
            () => productDetails.value,
            (prevValue, currValue) => {
                // console.log('Hello')
                if (prevValue !== currValue) {
                    state.suppliers = productDetails?.value?.product?.suppliers?.filter(
                        (value, index, self) => self.indexOf(value) === index
                    )
                    state.suppliers = state?.suppliers?.reduce((acc, currValue) => {
                        acc.push(currValue.company || currValue.suppliers)
                        return acc
                    }, [])

                    state.suppliers = state.suppliers?.filter((value, index, self) => self.indexOf(value) === index)
                }
            }
        )

        watch(
            () => state.endDate,
            (prevValue, currValue) => {
                // if (startDate && endDate) return
                const { startDate, endDate } = state
                const { id } = route.params
                const query = {
                    id,
                    startDate: new Date(startDate).toLocaleDateString('en-US'),
                    endDate: new Date(endDate).toLocaleDateString('en-US')
                }
                store.dispatch('Inventory/getProduct', query)
            }
        )

        // Modal for inventory

        const totalStockOnHand = ref(false)
        const handPurchaseValue = ref(false)
        const handSalesValue = ref(false)
        const noOfDaysInventory = ref(false)
        const totalQtyPurchase = ref(false)
        const totalCostOfPurchase = ref(false)
        const totalQtySold = ref(false)
        const totalSalesPrice = ref(false)

        const archive = product => {
            const quantity = product?.qty_in_stock ? parseInt(product?.qty_in_stock || 0) : 0
            if (quantity != 0) {
                Swal.fire(`Sorry, you can only archive a product with 0 quantity in stock`, '', 'info')
                return
            }

            Swal.fire({
                title: `Are you sure you want to archive this product - ${product.name}?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Yes, archive it!',
                denyButtonText: `No, cancel!`
            }).then(result => {
                if (result.isConfirmed) {
                    store.dispatch('Inventory/archiveProduct', product._id).then(_ => {
                        state.disableArchive = true
                    })
                }
            })
        }

        const toggleUpdateInventoryModal = (status, product = null) => {
            store.dispatch('Inventory/toggleUpdateInventoryModal', { status, product })
        }

        const onHandleCustomRange = async () => {
            state.startDate = formatDateFilter(state.startDate)
            state.endDate = formatDateFilter(state.endDate)
            const { id } = route.params
            const query = { id }
            await store.dispatch('Inventory/getProduct', query)
        }

        const downloadBarcode = () => {
            const barcodeBlob = document.querySelector('.vue3-barcode-element').getAttribute('src')
            const fileLink = document.createElement('a')
            fileLink.href = barcodeBlob
            // const fileName = `${orgData.value?.org} - ${date}`
            fileLink.setAttribute('download', `barcode.png`)
            fileLink.setAttribute('target', '_blank')
            document.body.appendChild(fileLink)
            fileLink.click()
            fileLink.remove()
        }

        onMounted(async () => {
            await onHandleCustomRange()
            store.dispatch('Settings/getBusinesses')
        })

        return {
            state,
            productDetails,
            productLoading,
            formatDate,
            formatAmount,
            formatQuantity,
            role,
            archive,
            toggleUpdateInventoryModal,
            onHandleCustomRange,
            rolePermissions,
            totalStockOnHand,
            handPurchaseValue,
            handSalesValue,
            noOfDaysInventory,
            totalQtyPurchase,
            totalCostOfPurchase,
            totalQtySold,
            totalSalesPrice,
            downloadBarcode,
        }
    },

    methods: {
        printBarcode() {
            this.$htmlToPaper('barcodeContainer')
        }
    }
}
</script>

<style scoped></style>
